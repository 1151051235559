@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/lightmode';

.member-list-item-v2 {
  $breakpoint: 700px;
  background-color: lightmode.$clean;
  border: 1px solid lightmode.$ui-border;
  display: flex;

  &:not(:last-child) {
    border-bottom-width: 0;
  }

  &:hover {
    background-color: lightmode.$clean-secondary;
  }

  &--open {
    background-color: lightmode.$background-surface-secondary;
    &:hover {
      background-color: lightmode.$background-primary;
    }

    .member-list-item-v2__detail {
      color: lightmode.$on-background-primary;
    }

    .member-list-item-v2__detail::after {
      background: lightmode.$on-background-primary !important;
    }

    .member-list-item-v2__metadata {
      color: lightmode.$on-background-primary;
    }
  }

  &__checkbox {
    margin: spacing.$s 0;
    padding: 0 spacing.$l;
    border-right: 1px solid lightmode.$ui-border;
    display: flex;
    align-items: center;

    &--selected {
      border-color: lightmode.$ui-graphical-elements-3;
    }
  }

  &__link {
    display: flex;
    flex-grow: 1;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: spacing.$m spacing.$l;
    width: 100%;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    text-decoration: none;
  }

  &__information {
    display: grid;
    grid-template-areas: 'main' 'details' 'tags' 'messages';
    gap: spacing.$xs;
    width: 100%;
    margin-right: spacing.$s;
    text-align: left;

    @media (min-width: $breakpoint) {
      grid-template-areas: 'main tags' 'details details' 'messages messages';
    }
  }

  &__messages {
    grid-area: messages;
    display: flex;
    column-gap: spacing.$xs;
    flex-wrap: wrap;
  }

  &__information-main {
    grid-area: main;
    display: flex;
    column-gap: spacing.$xs;
    flex-wrap: wrap;
  }

  &__dot {
    display: inline-block;
    background-color: currentColor;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    margin: 0 spacing.$xs;
  }

  &__metadata {
    color: lightmode.$on-background-secondary;
  }

  &__details {
    grid-area: details;
    @media (min-width: $breakpoint) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &__detail {
    color: lightmode.$on-background-secondary;

    &--highlighted {
      color: lightmode.$signal-warning-text;
    }

    @media (max-width: $breakpoint) {
      &:before {
        content: '\A';
        display: inline-block;
        margin: 0 spacing.$xs 3px 0;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: lightmode.$on-background-secondary;
      }
    }

    @media (min-width: $breakpoint) {
      &:not(:last-child) {
        &:after {
          content: '\A';
          display: inline-block;
          margin: 0 spacing.$xs 2px;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          background: lightmode.$on-background-secondary;
        }
      }
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-area: tags;
    @media (min-width: $breakpoint) {
      justify-content: flex-end;
    }
  }
}
